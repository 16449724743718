import { useCallback, useEffect, useMemo, useState } from "react";
import { useIonRouter } from "@ionic/react";
import { useLocation } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldValues, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/button";
import { PasswordInput } from "@/components/inputs/password-input";
import { UnauthenticatedPageLayout } from "@/components/layouts/UnauthenticatedPageLayout";
import { customErrorMap } from "@/utils/custom-error-map";
import { camelizeKeys } from "@/utils/object-functions";
import { request } from "@/utils/request";
import { useSetToken } from "@/hooks/authentication";
import { LoadingText } from "@/components/loading-text";

const formSchema = z.object({
  password: z.string().nonempty(),
});

const ConfirmEmailPage = () => {
  const { search } = useLocation();
  const router = useIonRouter();
  const [resetPasswordToken, setResetPasswordToken] = useState<string>();
  const [isLoadingResetPasswordToken, setIsLoadingResetPasswordToken] =
    useState(false);
  const [error, setError] = useState<string>();

  const { confirmationToken } = useMemo(
    () =>
      camelizeKeys(Object.fromEntries(new URLSearchParams(search).entries())),
    [search],
  );

  const confirmEmail = useCallback(async () => {
    setIsLoadingResetPasswordToken(true);

    const response = await request.post<{ resetPasswordToken: string }>(
      "/users/confirmations",
      {
        confirmationToken,
      },
    );

    if (response.ok && response.data?.resetPasswordToken) {
      setResetPasswordToken(response.data.resetPasswordToken);
    } else if (response.errors) {
      setError(response.errors.base[0]);
    }

    setIsLoadingResetPasswordToken(false);
  }, [confirmationToken]);

  useEffect(() => {
    confirmEmail();
  }, [confirmEmail]);

  const setToken = useSetToken();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: { password: "" },
    resolver: zodResolver(formSchema, { errorMap: customErrorMap }),
  });

  const onSubmit = async (data: FieldValues) => {
    const response = await request.put<{ token: string }>("/users/passwords", {
      ...data,
      resetPasswordToken,
    });

    if (response.ok && response.data?.token) {
      await setToken(response.data.token);

      router.push("/songs");
    } else if (response.errors) {
      setError(response.errors.base[0]);
    }
  };

  return (
    <UnauthenticatedPageLayout
      title="Set your password"
      className="max-w-screen-sm w-full mx-auto border rounded-lg bg-gray-100 p-4 flex flex-col gap-4 justify-center items-center min-h-48"
    >
      {isLoadingResetPasswordToken ? (
        <div className="flex flex-col gap-4 items-center w-full max-w-md">
          <LoadingText className="text-gray-500" />
        </div>
      ) : error ? (
        <div className="flex flex-col gap-4 items-center w-full max-w-md">
          <p className="text-red-500">{error}</p>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 items-center w-full max-w-md"
        >
          <PasswordInput
            name="password"
            label="New Password"
            control={control}
            placeholder="Create your password"
            hint="Must be at least 8 characters long."
          />

          <Button type="submit" className="btn-primary" loading={isSubmitting}>
            Update Password
          </Button>
        </form>
      )}
    </UnauthenticatedPageLayout>
  );
};

export default ConfirmEmailPage;
