import { HTMLAttributes, useState } from "react";
import { TextInput } from "@/components/inputs/text-input";
import { Control, FieldValues } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PasswordInputProps = HTMLAttributes<HTMLInputElement> & {
  name: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  control: Control<FieldValues>;
  required?: boolean;
  horizontal?: boolean;
  className?: string;
};

export const PasswordInput = (props: PasswordInputProps) => {
  const [type, setType] = useState("password");

  return (
    <TextInput
      type={type}
      {...props}
      className="rounded-l"
      endAdornment={
        <button
          type="button"
          onClick={() =>
            setType((type) => (type === "password" ? "text" : "password"))
          }
          className="bg-white hover:bg-gray-100 px-3 border border-solid border-gray-500 rounded-r"
        >
          <FontAwesomeIcon
            icon={type === "password" ? "eye" : "eye-slash"}
            fixedWidth
          />
        </button>
      }
    />
  );
};
