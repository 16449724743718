import { z } from "zod";

export const customErrorMap: z.ZodErrorMap = (error, ctx) => {
  if (error.code === "too_small" && error.minimum === 1) {
    return { message: "This field can't be blank." };
  }

  if (error.code === "too_small") {
    return {
      message: `This field must be at least ${error.minimum} characters long.`,
    };
  }

  if (error.code === "invalid_string" && error.validation === "email") {
    return { message: "Please enter a valid email address." };
  }

  if (error.code === "invalid_enum_value") {
    return { message: "Please select an option." };
  }

  if (error.code === "invalid_type" && error.expected === "boolean") {
    return { message: "Please select an option." };
  }

  if (error.code === "invalid_literal" && error.expected === true) {
    return {
      message: "You must check this checkbox to continue.",
    };
  }

  return { message: ctx.defaultError };
};
