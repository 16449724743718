import { IonContent, IonPage } from "@ionic/react";
import { HTMLAttributes, PropsWithChildren } from "react";
import { Link } from "react-router-dom";

import ss4kidslogo from "@/assets/ss4kids-logo-2-lines.png";

type UnauthenticatedPageLayoutProps = HTMLAttributes<HTMLDivElement> & {
  title?: string;
};

export const UnauthenticatedPageLayout = ({
  title,
  className,
  children,
}: PropsWithChildren<UnauthenticatedPageLayoutProps>) => {
  return (
    <IonPage>
      <IonContent fullscreen className="bg-white">
        <div className="max-w-screen-lg mx-auto flex flex-col gap-4 p-4 bg-white">
          <div className="flex items-center justify-center py-4">
            <Link to="/">
              <img src={ss4kidslogo} className="h-[90px] w-[245px]" />
            </Link>
          </div>

          {title && <h1 className="text-4xl text-center">{title}</h1>}

          <div className={className}>{children}</div>

          <footer className="grid grid-cols-4 gap-4">
            <a
              href="https://singandspeak4kids.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
              className="link text-center"
            >
              Privacy Policy
            </a>

            <a
              href="https://singandspeak4kids.com/terms-of-service"
              target="_blank"
              rel="noreferrer"
              className="link text-center"
            >
              Terms of Service
            </a>

            <div className="text-center">
              &copy; {new Date().getFullYear()}{" "}
              <a
                href="https://singandspeak4kids.com/about-us"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                iQsonics LLC
              </a>
            </div>

            <p className="text-center">All Rights Reserved</p>
          </footer>
        </div>
      </IonContent>
    </IonPage>
  );
};
