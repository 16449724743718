import { useAppContext } from "@/contexts/app-context";

type UseToken = () => [string | undefined, { isLoading: boolean }];

export const useToken: UseToken = () => {
  const {
    state: { authToken, isLoadingAuthToken },
  } = useAppContext();

  return [authToken, { isLoading: isLoadingAuthToken }];
};

export const useSetToken = () => {
  const {
    actions: { setAuthToken },
  } = useAppContext();

  return setAuthToken;
};
