import { HTMLAttributes, useEffect, useState } from "react";

type LoadingTextProps = HTMLAttributes<HTMLDivElement>;

export const LoadingText = ({
  children = "Loading",
  ...props
}: LoadingTextProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => (prevCount + 1) % 4);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div {...props}>
      {children}
      {".".repeat(count)}
    </div>
  );
};
