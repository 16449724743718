import { HTMLAttributes, ReactNode } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import { clsx } from "clsx";

type TextInputProps = HTMLAttributes<HTMLInputElement> & {
  name: string;
  type?: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  control: Control<FieldValues>;
  required?: boolean;
  horizontal?: boolean;
  className?: string;
};

export const TextInput = ({
  name,
  type = "text",
  label,
  hint,
  placeholder,
  startAdornment,
  endAdornment,
  control,
  required = false,
  horizontal = false,
  className,
  ...props
}: TextInputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div
      className={clsx("w-full flex gap-x-4 gap-y-2", {
        "flex-col": !horizontal,
      })}
    >
      {label && (
        <div
          className={clsx({
            "w-1/3 flex items-center justify-end h-11": horizontal,
          })}
        >
          <label htmlFor={name} className="text-end">
            {label}{" "}
            {required && (
              <span
                className="text-red-600 underline decoration-dotted cursor-help"
                title="required"
              >
                *
              </span>
            )}
          </label>
        </div>
      )}

      <div className="grow flex flex-col gap-1">
        <div className={clsx("flex", { "max-w-96": horizontal })}>
          {startAdornment}
          <input
            {...field}
            type={type}
            id={name}
            placeholder={placeholder}
            className={clsx("rounded grow", {
              "border-red-500": error,
              "border-l-0 rounded-l-none": startAdornment,
              "border-r-0 rounded-r-none": endAdornment,
              className,
            })}
            {...props}
          />
          {endAdornment}
        </div>
        {hint && <p className="text-xs text-gray-600">{hint}</p>}
        {error && <p className="text-sm text-red-600">{error.message}</p>}
      </div>
    </div>
  );
};
